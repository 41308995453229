import React from 'react';

import Header from './Header';
import MainFooter from './MainFooter';
import './Layout.css';



export default ({ children }) => {

    return (
        <div style={{margin: '1rem auto', maxWidth: 700, padding: '0 1rem'}}>
            <Header />
            <div className="layout-container">
                {children}
                <MainFooter />
            </div> 
         </div>
    )

}