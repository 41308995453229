import React from 'react';

import { Link } from 'gatsby';

import LogoHeader from './animations/LogoHeader';
import './animations/LogoHeader.css';

const Header = props => {

    return (
        <div className="header_logo_wrapper">
            <Link to="/"><LogoHeader /></Link>
            <Link to="/" style={{ textDecoration: 'none' }}><div className="header_logo_title"><h2>steinofen.rocks</h2></div></Link>
        </div>
    );
};

export default Header;